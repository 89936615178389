import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Documenton } from 'src/app/shared/models/documenton.model';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'dialog-documenton-media',
  templateUrl: './documenton-media.component.html',
  styleUrls: ['./documenton-media.component.scss'],
})
export class DocumentonMediaComponent implements OnInit {
  isActive: boolean = false;
  videoPath: string;

  constructor(
    public dialogRef: MatDialogRef<DocumentonMediaComponent>,
    private _ds: DataService
  ) {}

  ngOnInit(): void {
    this._ds.getAllDocumentons$().subscribe((documentons: Documenton[]) => {
      const i = Math.floor(Math.random() * documentons.length);
      this.videoPath = documentons[i].video_path;
    });
  }

  onClose(): void {
    this.isActive = false;
    setTimeout(() => this.dialogRef.close(), 50);
  }
}
