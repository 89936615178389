import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { config } from 'src/app/_config';
import { Event } from '../models/event.model';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  lastArchivedEvent: Event = null;
  liveEvent: Event = null;
  liveEvent$: Subject<Event> = new Subject();
  events: Event[] = [];

  constructor(private _ds: DataService) {
    this._init();
  }

  private _init(): void {
    this._ds.getAllEvents$().subscribe((events: Event[]) => {
      this.events = events;

      // Check immediately
      this.liveEvent = this.checkForLiveEvent();
      this.lastArchivedEvent = this.checkForLastArchivedEvent();

      // Check every 3min
      setInterval(() => {
        this.liveEvent = this.checkForLiveEvent();
        this.lastArchivedEvent = this.checkForLastArchivedEvent();
      }, 3 * 60 * 1000);
    });
  }

  checkForLiveEvent(): Event {
    const liveEvent = this.events.find((e) => {
      const status = EventService.getEventStatus(e);

      if (
        status === EventService.EventStatus.SOON ||
        status === EventService.EventStatus.DURING
      )
        return true;
      else return false;
    });

    this.liveEvent$.next(liveEvent);

    return liveEvent;
  }

  checkForLastArchivedEvent(): Event {
    const archivedEvents = this.events.filter((e) => e.archive_link);
    if (archivedEvents.length > 0)
      return archivedEvents[archivedEvents.length - 1];
    else return null;
  }

  getLiveEvent$(): Observable<Event> {
    return this.liveEvent$.asObservable();
  }

  static getEventStatus(event: Event): EventService.EventStatus {
    let timeNow = new Date();
    // timeNow = new Date('June 9 2021 09:55:53 GMT-0400'); ////DEBUG
    // timeNow = new Date('Dec 9 2020 18:30:53 GMT-0500'); ////DEBUG

    let timeSoon = new Date(
      new Date(event.date_start).getTime() - config.event.timeSoonOffset
    );
    let timeStart = new Date(event.date_start);
    let timeEnd = new Date(event.date_end);

    let status: EventService.EventStatus;
    if (timeNow < timeSoon) status = EventService.EventStatus.BEFORE;
    else if (timeSoon <= timeNow && timeNow < timeStart)
      status = EventService.EventStatus.SOON;
    else if (timeStart <= timeNow && timeNow < timeEnd)
      status = EventService.EventStatus.DURING;
    else status = EventService.EventStatus.AFTER; // if(timeEnd <= timeNow)

    return status;
  }
}

export namespace EventService {
  export enum EventStatus {
    BEFORE = 0,
    SOON = 1,
    DURING = 2,
    AFTER = 3,
  }
}
