import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RenderProjectionService } from 'src/app/shared/services/render-projection.service';
import { config } from 'src/app/_config';
import { AtelierMenuWrapperDirective } from './atelier-menu-wrapper.directive';

@Component({
  selector: 'app-atelier-menu',
  templateUrl: './atelier-menu.component.html',
  styleUrls: ['./atelier-menu.component.scss'],
})
export class AtelierMenuComponent implements AfterViewInit {
  @ViewChild(AtelierMenuWrapperDirective) amw: AtelierMenuWrapperDirective;
  asset: any = config.assets.find((a) => a.name === 'ui.pull');
  isActive: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AtelierMenuComponent>,
    private _rps: RenderProjectionService
  ) {}

  ngAfterViewInit(): void {
    this._rps.addOBR(
      () => [this.asset.pos],
      (sc, i) => {
        this.amw.setPos(sc);
      },
      true
    );

    setTimeout(() => (this.isActive = true), 250);
  }

  onLink(link: string): void {
    window.open(link, '_blank');
  }

  onClose(): void {
    this.isActive = false;
    setTimeout(() => this.dialogRef.close(), 50);
  }
}
