import { Injectable } from '@angular/core';
declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor() {
    this.init();
  }

  init(): void {
    gtag('config', 'G-XVPGFQXH6Z', {
      send_page_view: true,
    });
  }

  trackUIEvents(uiElement: string): void {
    gtag('event', uiElement, {
      value: 1,
    });
  }
}
