import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { Personnel } from 'src/app/shared/models/personnel.model';
import { ActiveDialogService } from 'src/app/shared/services/active-dialog.service';
import { DataService } from 'src/app/shared/services/data.service';
import { LightingManagerService } from 'src/app/shared/services/lighting-manager.service';
import { PersonnelComponent } from '../dialog/personnel/personnel.component';

@Component({
  selector: 'ui-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent implements OnInit, OnDestroy {
  private _sub: Subscription = new Subscription();

  @Input() drawer: MatSidenav;
  @ViewChildren(MatExpansionPanel)
  expansionPanels: QueryList<MatExpansionPanel>;
  isReopen: boolean = false;

  personnel: any;

  events: { e: Event; status: number }[];
  recentEvents: Event[];

  constructor(
    private _ds: DataService,
    private _activeDialog: ActiveDialogService,
    private _dialog: MatDialog,
    private _lms: LightingManagerService
  ) {}

  ngOnInit(): void {
    // Reset the accordians in the drawer on close
    this.drawer.closedStart.subscribe(() => {
      if (!this.isReopen) this.resetExpanders();
    });

    // Close the drawer when a dialog opens
    this._sub.add(
      this._activeDialog.getDialogOpened$().subscribe((isOpen: boolean) => {
        if (isOpen) {
          this.isReopen = this.drawer.opened;
          this.drawer.close();
        } else {
          if (this.isReopen) this.drawer.open();
          this.isReopen = false;
        }
      })
    );

    // Get Personnel
    this._ds.getAllPersonnel$().subscribe((personnel: Personnel[]) => {
      this.personnel = [...new Set(personnel.map((p) => p.cat))].reduce(
        (a, x) => ({ ...a, [x]: [] }),
        {}
      );
      personnel.forEach((p) => this.personnel[p.cat].push(p));
    });
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  resetExpanders(): void {
    this.expansionPanels.forEach((ep, i) => {
      // if (i === 0) ep.open();
      // else ep.close();
      ep.close();
    });
  }

  onPersonnel(person: Personnel): void {
    if (person.thumb_path.length === 0 && person.profile.length === 0) return;

    const df = this._dialog.open(PersonnelComponent, {
      panelClass: [
        this._lms.isDarkMode ? 'dark-mode' : 'light-mode',
        'personnel-panel',
      ],
      backdropClass: 'transparent-backdrop',
      autoFocus: false,
      data: {
        personnel: person,
      },
    });

    this._activeDialog.setDialogOpen(df);
  }
}
