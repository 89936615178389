import { Directive, ElementRef } from '@angular/core';
import { Vector3 } from '@babylonjs/core';

@Directive({
  selector: '[documentonFilterWrapper]',
})
export class DocumentonFilterWrapperDirective {
  constructor(public elem: ElementRef) {}

  setPos(screenCoords: Vector3): void {
    this.elem.nativeElement.style.left = `${screenCoords.x}px`;
    this.elem.nativeElement.style.top = `50%`;
  }
}
