<ng-scrollbar>
  <!-- List -->
  <mat-accordion>
    <mat-nav-list>

      <!-- Information -->
      <div class="list-card slide-in">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h2>
                Information
              </h2>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <h4 class="w-600">
            ACTS OF URBANISM / ENTRE ACTES D’URBANISME
          </h4>
          <p>
            ACTS OF URBANISM / ENTRE ACTES D’URBANISME is an experimental research initiative at the intersection of
            design, urbanism and the performing arts, conceived to advance an expanded set of critical tools for the
            interpretation of cities. Launched in January 2020, the project cultivates international collaborations,
            both embodied and virtual, to enroll residents, designers, researchers, and artists in the analysis of the
            built environment. A combination of public events, workshops, and performances form a catalog of
            observations, with which new modalities for urban action may be sponsored.
          </p><br>
          <h4 class="w-600">
            BACKSTORY
          </h4>
          <p>
            This project is the logical evolution of the Insoutenable Architecture Program, launched by the Institut
            français and the Ministère de la Culture et de la Communication in 2013. This program, centered on convening
            conversations around pressing urban issues in the Americas, first focused on Brazil, Columbia, Venezuela,
            Haïti, shifting later to Mexico, Cuba, and the United States. After 7 years of incubation and in view of the
            global health crisis, Acts of Urbanism offers the opportunity to translate public debate into virtual
            collaboration and actionable projects.
          </p><br>
          <h4 class="w-600">
            PARTNERS
          </h4>
          <p>
            Acts of Urbanism is a franco-american collaborative project, which brings together the Université Grenoble
            Alpes, le laboratoire PACTE, la Cité du design - Saint-Etienne, Taubman College of Architecture and Urban
            Planning (University of Michigan), Le Pacifique – CDCN Grenoble Auvergne-Rhône-Alpes, Akoaki – Detroit
            Programming is produced in collaboration with the French Embassy in Chicago, the Institut Français, with
            support from the FACE Foundation (Transitioning to Virtual Exchange Fund).
          </p><br>
          <h4 class="w-600">
            ABOUT THIS WEBSITE
          </h4>
          <p>
            <a href="https://www.a-u.tube"
              target="_blank"
              rel="noopener noreferrer">https://www.a-u.tube</a> is a home base for Acts of Urbanism programming -- both
            visitors and participants. It can be understood in four parts:
          </p>
          <ol>
            <li>An atelier where participants can meet informally and work collaboratively.</li>
            <li>A performance space that hosts live events, symposia and other public programming.</li>
            <li>An archive of participants’ work, past and present.</li>
            <li>The "Documenton"; an experimental documentary-generator that creates algorithmically generated
              documentary content in response to users-selected themes.</li>
          </ol>
        </mat-expansion-panel>
      </div>

      <!-- Personnel -->
      <div class="list-card slide-in personnel">
        <mat-expansion-panel *ngIf="personnel">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h2>
                Personnel
              </h2>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <!-- Artists -->
          <h3 class="header w-500">Participating Artists</h3>
          <ul>
            <li *ngFor="let person of personnel.artist"
              [class.link]="person.thumb_path.length > 0 || person.profile.length > 0"
              (click)="onPersonnel(person)">
              <mat-icon *ngIf="person.thumb_path.length > 0 || person.profile.length > 0">circle</mat-icon>
              <div>
                {{person.name}}<i *ngIf="person.org.length > 0">, {{person.org}}</i>
              </div>
            </li>
          </ul>

          <!-- Partners -->
          <h3 class="header w-500">Institutional Partners</h3>
          <ul>
            <!-- CGFC -->
            <li class="subheader">Consulat Général de France à Chicago:</li>
            <li *ngFor="let person of personnel.cgfc"
              [class.link]="person.thumb_path.length > 0 || person.profile.length > 0"
              (click)="onPersonnel(person)">
              <mat-icon *ngIf="person.thumb_path.length > 0 || person.profile.length > 0">circle</mat-icon>
              {{person.name}}
            </li>
            <!-- UM -->
            <li class="subheader">University of Michigan Taubman College:</li>
            <li *ngFor="let person of personnel.um"
              [class.link]="person.thumb_path.length > 0 || person.profile.length > 0"
              (click)="onPersonnel(person)">
              <mat-icon *ngIf="person.thumb_path.length > 0 || person.profile.length > 0">circle</mat-icon>
              {{person.name}}
            </li>
            <!-- AK -->
            <li class="subheader">Akoaki:</li>
            <li *ngFor="let person of personnel.ak"
              [class.link]="person.thumb_path.length > 0 || person.profile.length > 0"
              (click)="onPersonnel(person)">
              <mat-icon *ngIf="person.thumb_path.length > 0 || person.profile.length > 0">circle</mat-icon>
              {{person.name}}
            </li>
            <!-- CDD -->
            <li class="subheader">Cité du design:</li>
            <li *ngFor="let person of personnel.cdd"
              [class.link]="person.thumb_path.length > 0 || person.profile.length > 0"
              (click)="onPersonnel(person)">
              <mat-icon *ngIf="person.thumb_path.length > 0 || person.profile.length > 0">circle</mat-icon>
              {{person.name}}
            </li>
            <!-- IA -->
            <li class="subheader">Insoutenable Architecture:</li>
            <li *ngFor="let person of personnel.ia"
              [class.link]="person.thumb_path.length > 0 || person.profile.length > 0"
              (click)="onPersonnel(person)">
              <mat-icon *ngIf="person.thumb_path.length > 0 || person.profile.length > 0">circle</mat-icon>
              {{person.name}}
            </li>
          </ul>

          <!-- IF -->
          <h3 class="header w-500">Institut Français</h3>
          <ul>
            <li *ngFor="let person of personnel.if"
              [class.link]="person.thumb_path.length > 0 || person.profile.length > 0"
              (click)="onPersonnel(person)">
              <mat-icon *ngIf="person.thumb_path.length > 0 || person.profile.length > 0">circle</mat-icon>
              {{person.name}}
            </li>
          </ul>

          <!-- LP -->
          <h3 class="header w-500">Le Pacifique, Centre de Développement Chorégraphique National</h3>
          <ul>
            <li *ngFor="let person of personnel.lp"
              [class.link]="person.thumb_path.length > 0 || person.profile.length > 0"
              (click)="onPersonnel(person)">
              <mat-icon *ngIf="person.thumb_path.length > 0 || person.profile.length > 0">circle</mat-icon>
              {{person.name}}
            </li>
          </ul>

          <!-- UGA -->
          <h3 class="header w-500">Université Grenoble Alpes, Institut d’Urbanisme et de Géographie Alpine</h3>
          <ul>
            <li *ngFor="let person of personnel.uga"
              [class.link]="person.thumb_path.length > 0 || person.profile.length > 0"
              (click)="onPersonnel(person)">
              <mat-icon *ngIf="person.thumb_path.length > 0 || person.profile.length > 0">circle</mat-icon>
              {{person.name}}
            </li>
          </ul>

          <div class="logo">
            <div class="double">
              <img src="assets/thumbnails/logos/cdd.png">
              <img src="assets/thumbnails/logos/if.jpeg">
            </div>
            <div class="double">
              <img src="assets/thumbnails/logos/face.png">
              <img src="assets/thumbnails/logos/lp.png">
            </div>
            <img class="single"
              src="assets/thumbnails/logos/mcufeu.jpeg">
            <img class="single"
              src="assets/thumbnails/logos/akoaki.png">
            <img class="single"
              src="assets/thumbnails/logos/um.jpg">
          </div>
        </mat-expansion-panel>
      </div>

      <!-- Contact -->
      <div class="list-card slide-in">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h2>
                Contact Us
              </h2>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <form-contact></form-contact>
        </mat-expansion-panel>
      </div>

    </mat-nav-list>
  </mat-accordion>
</ng-scrollbar>
