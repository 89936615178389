<div class="header">
  <h4 class="title">Documenton</h4>
  <button mat-mini-fab
    (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="content">
  <vg-player *ngIf="videoPath">
    <vg-overlay-play></vg-overlay-play>
    <vg-buffering></vg-buffering>

    <vg-scrub-bar>
      <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
      <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
    </vg-scrub-bar>

    <vg-controls>
      <vg-play-pause></vg-play-pause>

      <vg-time-display vgProperty="current"
        vgFormat="mm:ss"></vg-time-display>
      <div class="slash">
        <span class="mat-h4">/</span>
      </div>
      <vg-time-display vgProperty="total"
        vgFormat="mm:ss"></vg-time-display>

      <span class="spacer"></span>
      <vg-mute></vg-mute>
      <vg-volume></vg-volume>

      <vg-fullscreen></vg-fullscreen>
    </vg-controls>

    <video #media
      [vgMedia]="media"
      [src]="videoPath"
      id="singleVideo"
      preload="auto">
    </video>
  </vg-player>
</div>
