import { Platform } from '@angular/cdk/platform';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  Action,
  ActionManager,
  AnimationGroup,
  AssetContainer,
  ExecuteCodeAction,
  InstantiatedEntries,
  Mesh,
  PlayAnimationAction,
  PredicateCondition,
  ShadowGenerator,
  TransformNode,
  Vector3,
} from '@babylonjs/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { CameraService } from 'src/app/engine/services/camera.service';
import { ArchiveComponent } from 'src/app/ui/dialog/archive/archive.component';
import { AtelierMenuComponent } from 'src/app/ui/dialog/atelier-menu/atelier-menu.component';
import { DocumentonFilterComponent } from 'src/app/ui/dialog/documenton-filter/documenton-filter.component';
import { DocumentonMediaComponent } from 'src/app/ui/dialog/documenton-media/documenton-media.component';
import { config } from 'src/app/_config';
import { Event } from '../models/event.model';
import { ActiveDialogService } from './active-dialog.service';
import { AnalyticsService } from './analytics.service';
import { EventService } from './event.service';
import { LightingManagerService } from './lighting-manager.service';
import { RenderProjectionService } from './render-projection.service';

export interface AssetConfig {
  name: string;
  gltf_path: string;
  lightmap_path: string;
  loadOn?: string;
  anim: string[];
  shadow: {
    cast: boolean;
    receive: boolean;
  };
  anim_delay?: number;
  disableBackfaceCull?: boolean;
  onClickStart?: (wms: WebglManagerService, args?: any) => void;
  onClickEnd?: (wms: WebglManagerService, args?: any) => void;
  onToggleStart?: (wms: WebglManagerService, args?: any) => void;
  onToggleEnd?: (wms: WebglManagerService, args?: any) => void;
  onTriggerStart?: (wms: WebglManagerService, args?: any) => void;
  onTriggerEnd?: (wms: WebglManagerService, args?: any) => void;
}

class AnimatedAsset {
  name: string;
  animTypes: string[];
  interactAction: Action;
  triggerAction?: () => void;
  root: TransformNode = null;
  selectableMesh: Mesh;
  anim = {
    idle: null,
    hover: null,
    click: null,
    toggle: null,
    trigger: null,
  };
  isActive: boolean = true;
  loadOn: string = '';

  get actionManager(): ActionManager {
    return this.selectableMesh.actionManager as ActionManager;
  }

  constructor(
    private _wms: WebglManagerService,
    public assetConfig: AssetConfig,
    private _container: AssetContainer,
    public shadowGenerator?: ShadowGenerator,
    public options?: AssetOptions
  ) {
    this._init();
  }

  private _init(): void {
    this.name = this.assetConfig.name;
    this.animTypes = this.assetConfig.anim;
    if (this.assetConfig.loadOn) this.loadOn = this.assetConfig.loadOn;
  }

  instantiate(isStartIdle: boolean = false): void {
    if (this.root) return;

    const ie: InstantiatedEntries = this._container.instantiateModelsToScene();
    this.assignRoot(ie.rootNodes[0]);
    this.assignAnimations(ie.animationGroups);

    if (isStartIdle) {
      if (this.anim.idle) this.anim.idle.play(true);
    }
  }

  dispose(): void {
    if (!this.root) return;

    Object.values(this.anim)
      .filter((a) => a)
      .forEach((a) => a.stop());
    if (this.selectableMesh) this.actionManager.dispose();
    this.root.dispose();
  }

  assignRoot(root: TransformNode): void {
    root.metadata = { manager: this }; // Give the root a reference to the entire container

    //Set some options
    const childMeshes = root.getChildMeshes();
    // isPickable
    if (this.options.isPickable)
      childMeshes.forEach((mesh) => (mesh.isPickable = true));
    else childMeshes.forEach((mesh) => (mesh.isPickable = false));
    // backFaceCulling
    if (this.options.backFaceCulling)
      childMeshes.forEach((mesh) => (mesh.material.backFaceCulling = true));
    // shadows
    if (this.options.castShadow)
      childMeshes.forEach((mesh) =>
        this.shadowGenerator.addShadowCaster(mesh, true)
      );
    if (this.options.receiveShadow)
      childMeshes.forEach((mesh) => (mesh.receiveShadows = true));

    // Make everything unselectable
    childMeshes.forEach((mesh) => (mesh.isPickable = false));

    // Save to the class
    this.root = root;
    this.selectableMesh = this.root
      .getChildMeshes()
      .find((m) => /selectable/.test(m.name)) as Mesh;
    // Only objs that have a selectable mesh can have an actionmanager
    if (this.selectableMesh) {
      this.selectableMesh.isPickable = true;
      this.selectableMesh.actionManager = new ActionManager(
        this.root.getScene()
      );
    }
  }

  assignAnimations(animGroups: AnimationGroup[]): void {
    if (this.animTypes.length === 0) return;

    // Assign the animations
    this.animTypes.map((animType) => {
      // Get the regex for finding animations in the models
      let re = new RegExp(`^${animType}`);

      // Assign the new animation group
      this.anim[animType] = new AnimationGroup(animType, this.root.getScene());
      if (/hover|click|toggle|trigger/.test(animType))
        this.assignAction(animType);

      // Assign animations to the group
      animGroups
        .filter((ag) => re.test(ag.name))
        .forEach((a) => {
          a.targetedAnimations.forEach((at) =>
            this.anim[animType].addTargetedAnimation(at.animation, at.target)
          );
        });
    });
  }

  assignAction(type: string): void {
    /* Click */
    if (type === 'click') {
      // Sub to reset button after anim
      this.anim.click.onAnimationGroupEndObservable.add(() => {
        this.isActive = true;
        // Execute poist animation functions
        if (this.assetConfig.onClickEnd) {
          let args = {};
          this.assetConfig.onClickEnd(this._wms, args);
        }
      });

      // Click action
      this.actionManager.registerAction(
        new ExecuteCodeAction(
          ActionManager.OnPickTrigger,
          () => {
            this.isActive = false; // Set to prevent multiple clicks
            // Execute any functions before starting the animation
            if (this.assetConfig.onClickStart) {
              let args = {};
              this.assetConfig.onClickStart(this._wms, args);
            }
            // Start the animation
            this.anim.click.play(false);
          },
          new PredicateCondition(this.actionManager, () => this.isActive)
        )
      );

      // Save it
      this.interactAction = this.actionManager.actions[
        this.actionManager.actions.length - 1
      ] as Action;
    }

    /* Toggle */
    if (type === 'toggle') {
      // Start as inactive
      this.isActive = false;

      // Sub to change state button after anim
      this.anim.toggle.onAnimationGroupEndObservable.add(() => {
        this.isActive = !this.isActive;
        // Execute poist animation functions
        if (this.assetConfig.onToggleEnd) {
          let args = {
            isActive: this.isActive,
          };
          this.assetConfig.onToggleEnd(this._wms, args);
        }
      });

      // Toggle action
      this.actionManager.registerAction(
        new ExecuteCodeAction(ActionManager.OnPickTrigger, () => {
          // Execute any functions before starting the animation
          if (this.assetConfig.onToggleStart) {
            let args = {
              isActive: !this.isActive, // Have to reverse isActive as it isn't set until after anim
            };
            this.assetConfig.onToggleStart(this._wms, args);
          }

          // Set the animation direction
          let from, to;
          if (!this.isActive) {
            from = this.anim.toggle.from;
            to = this.anim.toggle.to;
          } else {
            from = this.anim.toggle.to;
            to = this.anim.toggle.from;
          }
          // Start the animation
          this.anim.toggle.start(false, 1, from, to, false);
        })
      );

      // Save it
      this.interactAction = this.actionManager.actions[
        this.actionManager.actions.length - 1
      ] as Action;
    }

    /* Trigger */
    if (type === 'trigger') {
      // Sub to reset button after anim
      this.anim.trigger.onAnimationGroupEndObservable.add(() => {
        // Execute poist animation functions
        if (this.assetConfig.onTriggerEnd) {
          let args = {};
          this.assetConfig.onTriggerEnd(this._wms, args);
        }
      });

      // trigger action
      this.triggerAction = () => {
        // Execute any functions before starting the animation
        if (this.assetConfig.onTriggerStart) {
          let args = {};
          this.assetConfig.onTriggerStart(this._wms, args);
        }
        // Start the animation
        this.anim.trigger.play(false);
      };
    }
  }
}

export interface AssetOptions {
  castShadow?: boolean;
  receiveShadow?: boolean;
  backFaceCulling?: boolean;
  isPickable?: boolean;
  animDelay?: number;
}

@Injectable({
  providedIn: 'root',
})
export class WebglManagerService implements OnDestroy {
  private _sub: Subscription = new Subscription();

  loadedNum: number = 0;
  private loadedNum$: Subject<number> = new Subject();
  loadedTotal: number;
  loadingMessages: { index: number; message: string }[] = [];

  assets: AnimatedAsset[] = [];

  private _isEngineResize$: Subject<boolean> = new Subject();

  constructor(
    public ngZone: NgZone,
    public platform: Platform,
    private _lms: LightingManagerService,
    private _cs: CameraService,
    private _dialog: MatDialog,
    private _rps: RenderProjectionService,
    private _ads: ActiveDialogService,
    private _eventService: EventService,
    private _analyticsService: AnalyticsService
  ) {
    this._init();
  }

  private _init(): void {
    this.calculateTotalAssets();
    this.getLoadedNum$().subscribe({
      complete: () => {
        this.startIdleAnim();
        this._cs.toggleAutoRotate(true);
      },
    });

    this.getLoadedNum$().subscribe({
      complete: () => {
        this._sub.add(
          this._eventService.getLiveEvent$().subscribe((liveEvent: Event) => {
            if (liveEvent) {
              this.assets
                .filter((a) => a.loadOn === 'event')
                .forEach((a) => a.instantiate(true));
            } else {
              this.assets
                .filter((a) => a.loadOn === 'event')
                .forEach((a) => a.dispose());
            }
          })
        );

        // Trigger Immediate load
        this._eventService.checkForLiveEvent();
      },
    });
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  /* Loading */
  addAsset(assetPath: AssetConfig, container: AssetContainer): void {
    // Set the options
    let options = {
      isPickable: true,
      backFaceCulling: assetPath.disableBackfaceCull ? false : true,
      castShadow: false,
      receiveShadow: true,
      animDelay: assetPath.anim_delay ? assetPath.anim_delay : 0,
    };

    // Make it
    let asset = new AnimatedAsset(
      this,
      assetPath,
      container,
      undefined,
      options
    );
    this.assets.push(asset);

    // Count it
    this.addLoadedAsset();

    // Check to see if it should be instantiated
    if (assetPath.loadOn) {
      if (assetPath.loadOn !== 'desktop') return;
      else if (this.platform.IOS || this.platform.ANDROID) return;
    }

    // instantiate the asset
    asset.instantiate();
  }

  addLoadedAsset(): void {
    this.loadedNum++;
    this.loadedNum$.next(this.loadedNum);
    if (this.loadedNum >= this.loadedTotal) this.loadedNum$.complete();
  }

  getLoadedNum$(): Observable<number> {
    return this.loadedNum$.asObservable();
  }

  calculateTotalAssets(): void {
    this.loadedTotal = config.assets.length;
  }

  /* Animation Helpers */
  startIdleAnim(): void {
    this.assets
      .filter((a) => a.anim.idle)
      .forEach((a) => {
        if (a.options.animDelay)
          setTimeout(() => a.anim.idle.play(true), a.options.animDelay);
        else a.anim.idle.play(true);
      });
  }

  setLightingMode(isDarkMode: boolean): void {
    this._lms.toggleLighting(isDarkMode);
  }

  getMoveTarget(screenSpaceTarget: Vector3, zPos: number): Vector3 {
    return this._rps.getWorldCoords(screenSpaceTarget, zPos);
  }

  moveTo(
    deltaTarget: Vector3,
    duration: number = 1000,
    onComplete?: () => void
  ): void {
    this._cs.moveTo(
      this._cs.camera.target.add(deltaTarget),
      duration,
      onComplete
    );
  }

  moveReset(duration: number = 1000, onComplete?: () => void): void {
    this._cs.moveTo(config.camera.arcrotate.target, duration, onComplete);
  }

  rotateTo(
    alpha: number,
    beta: number,
    duration: number = 1000,
    onComplete?: () => void
  ): void {
    this._cs.rotate(alpha, beta, duration, onComplete);
  }

  toggleAutoRotate(isAutoRotate: boolean): void {
    this._cs.toggleAutoRotate(isAutoRotate, true);
  }

  /* Dialog */
  openLiveEvent(): void {
    if (this._eventService.liveEvent)
      window.open(this._eventService.liveEvent.event_link, '_blank');
    else if (this._eventService.lastArchivedEvent) {
      window.open(this._eventService.lastArchivedEvent.archive_link, '_blank');
    }
  }

  openDocumentonFilter(): MatDialogRef<DocumentonFilterComponent> {
    const df = this._dialog.open(DocumentonFilterComponent, {
      panelClass: [
        this._lms.isDarkMode ? 'dark-mode' : 'light-mode',
        'docu-filter-panel',
      ],
      backdropClass: 'transparent-backdrop',
      autoFocus: false,
      data: {
        trigger: this.assets.find((a) => a.name === 'ui.button').triggerAction,
      },
    });
    this._ads.setDialogOpen(df);
    return df;
  }

  openDocumentonMedia(): MatDialogRef<DocumentonMediaComponent> {
    const df = this._dialog.open(DocumentonMediaComponent, {
      panelClass: [
        this._lms.isDarkMode ? 'dark-mode' : 'light-mode',
        'docu-media-panel',
      ],
      backdropClass: 'transparent-backdrop',
      autoFocus: false,
    });
    this._ads.setDialogOpen(df);
    return df;
  }

  openArchiveDialog(): MatDialogRef<ArchiveComponent> {
    const df = this._dialog.open(ArchiveComponent, {
      panelClass: [
        this._lms.isDarkMode ? 'dark-mode' : 'light-mode',
        'archive-panel',
      ],
      backdropClass: 'transparent-backdrop',
      autoFocus: false,
    });
    this._ads.setDialogOpen(df);
    return df;
  }

  openAtelierMenu(): MatDialogRef<AtelierMenuComponent> {
    const df = this._dialog.open(AtelierMenuComponent, {
      panelClass: [
        this._lms.isDarkMode ? 'dark-mode' : 'light-mode',
        'atelier-menu',
      ],
      backdropClass: 'transparent-backdrop',
      autoFocus: false,
    });
    this._ads.setDialogOpen(df);
    return df;
  }

  /* Helpers */
  getEngineResize$(): Observable<boolean> {
    return this._isEngineResize$.asObservable();
  }

  notifyEngineResize(): void {
    this._isEngineResize$.next(true);
  }

  trackUIEvent(uiElement: string): void {
    this._analyticsService.trackUIEvents(uiElement);
  }
}
