import { NgModule } from '@angular/core';

// Structural
import { PlatformModule } from '@angular/cdk/platform';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FlexLayoutModule } from '@angular/flex-layout';

// Organizational
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';

// Atomic
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';

// Effects
import { NgScrollbarModule } from 'ngx-scrollbar';

// Form
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  exports: [
    PlatformModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    NgScrollbarModule,
    MatTooltipModule,
    MatSidenavModule,
    MatToolbarModule,
    MatDialogModule,
    MatExpansionModule,
    MatListModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
  ],
})
export class MaterialModule {}
