import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { WebglManagerService } from 'src/app/shared/services/webgl-manager.service';
import { LightingManagerService } from 'src/app/shared/services/lighting-manager.service';

@Component({
  selector: 'ui-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss'],
})
export class QuickLinksComponent implements OnInit {
  @Input() drawer: MatSidenav;
  isEnabled: boolean = true;

  constructor(
    public lms: LightingManagerService,
    private _wms: WebglManagerService
  ) {}

  ngOnInit(): void {}

  onQuickLink(assetName: string): void {
    if (!this.isEnabled) return;

    const asset = this._wms.assets.find((a) => a.name === assetName);
    asset.interactAction.execute();

    // Dirty way to prevent multi clicks
    this.isEnabled = false;
    setTimeout(() => (this.isEnabled = true), 2500);
  }
}
