import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RenderProjectionService } from 'src/app/shared/services/render-projection.service';
import { config } from 'src/app/_config';
import { DocumentonFilterWrapperDirective } from './documenton-filter-wrapper.directive';

@Component({
  selector: 'dialog-documenton-filter',
  templateUrl: './documenton-filter.component.html',
  styleUrls: ['./documenton-filter.component.scss'],
})
export class DocumentonFilterComponent implements AfterViewInit {
  // @ViewChild(DocumentonFilterWrapperDirective)
  // dfwd: DocumentonFilterWrapperDirective;
  // asset: any = config.assets.find((a) => a.name === 'ui.button');
  isActive: boolean = false;

  numSelected: number = 0;
  options: string[][] = [
    [
      'AUTONOMY',
      'PUBLIC',
      'ADVOCACY',
      'COMMUNITY',
      'RESILIENCE',
      'CREATIVITY',
      'ENTERPRISE',
      'IDENTITY',
      'SOLIDARITY',
      'FOUNDATION',
    ],
    [
      'CORROBORATION',
      'CO-DESIGN',
      'TECHNOLOGY',
      'PLACE',
      'SUBURB',
      'INFRASTRUCTURE',
      'MARGIN',
      'CULTURE',
      'RADICAL',
      'FRICHE',
    ],
  ];

  constructor(
    public dialogRef: MatDialogRef<DocumentonFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { trigger: () => void }
  ) // private _rps: RenderProjectionService
  {}

  ngAfterViewInit(): void {
    // this._rps.addOBR(
    //   () => [this.asset.pos],
    //   (sc, i) => {
    //     this.dfwd.setPos(sc);
    //   },
    //   true
    // );
  }

  onClose(): void {
    this.isActive = false;
    setTimeout(() => this.dialogRef.close(), 50);
  }

  onCheck(isChecked: boolean): void {
    if (isChecked) this.numSelected++;
    else this.numSelected--;
  }

  onGenerate(): void {
    this.onClose();
    setTimeout(() => {
      this.data.trigger();
    }, 250);
  }
}
