<div class="header">
  <h4 class="title">Documenton</h4>
  <button mat-mini-fab
    (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="content">
  <ng-scrollbar>
    <h4>The "Documenton" is a documentary generator that creates algorithmically generated documentary content in
      response
      to the 3 keywords that you select (below):</h4>
    <mat-divider></mat-divider>
    <div class="checkboxes">
      <div *ngFor="let list of options">
        <mat-checkbox #cb
          *ngFor="let opt of list"
          color="primary"
          (change)="onCheck($event.checked)"
          [disabled]="numSelected >= 3 && !cb.checked">{{opt}}</mat-checkbox>
      </div>
    </div>
    <mat-divider></mat-divider>
    <button mat-flat-button
      [disabled]="numSelected === 0"
      color="primary"
      class="uc"
      (click)="onGenerate()">Generate</button>
  </ng-scrollbar>
</div>
