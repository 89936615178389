import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AssetManagerService } from 'src/app/engine/services/asset-manager.service';

@Injectable({
  providedIn: 'root',
})
export class LightingManagerService {
  private _isDarkMode$: Subject<boolean> = new Subject();
  isDarkMode: boolean = false;

  constructor(private _ngZone: NgZone) {}

  toggleLighting(setIsDarkMode?: boolean): void {
    if (setIsDarkMode !== undefined) this.isDarkMode = setIsDarkMode;
    else this.isDarkMode = !this.isDarkMode;

    this._ngZone.run(() => this._isDarkMode$.next(this.isDarkMode));
  }

  getLightingMode$(): Observable<boolean> {
    return this._isDarkMode$.asObservable();
  }
}
