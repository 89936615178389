import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { config } from 'src/app/_config';
import { Archive } from '../models/archive.model';
import { Documenton } from '../models/documenton.model';
import { Event } from '../models/event.model';
import { Personnel } from '../models/personnel.model';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private _http: HttpClient) {}

  getAllArchives$(): Observable<Archive[]> {
    return this._http.get<Archive[]>(
      config.data.base_path + config.data.archive
    );
  }

  getAllPersonnel$(): Observable<Personnel[]> {
    return this._http.get<Personnel[]>(
      config.data.base_path + config.data.personnel
    );
  }

  getAllEvents$(): Observable<Event[]> {
    return this._http.get<Event[]>(config.data.base_path + config.data.events);
  }

  getAllDocumentons$(): Observable<Documenton[]> {
    return this._http.get<Documenton[]>(
      config.data.base_path + config.data.documentons
    );
  }
}
