import { Component, OnInit } from '@angular/core';
import { LightingManagerService } from 'src/app/shared/services/lighting-manager.service';

@Component({
  selector: 'ui-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss'],
})
export class BackgroundComponent implements OnInit {
  constructor(public lms: LightingManagerService) {}

  ngOnInit(): void {}
}
