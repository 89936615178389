import { Injectable, OnDestroy } from '@angular/core';
import {
  Scene,
  Color3,
  Color4,
  GlowLayer,
  DirectionalLight,
  PBRMaterial,
  SceneLoader,
  AssetContainer,
  ShadowGenerator,
} from '@babylonjs/core';
import '@babylonjs/loaders/glTF';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { LightingManagerService } from 'src/app/shared/services/lighting-manager.service';
import {
  AssetConfig,
  WebglManagerService,
} from 'src/app/shared/services/webgl-manager.service';
import { config } from 'src/app/_config';

const ASSET_PATH = 'assets/webgl/';

@Injectable({
  providedIn: 'root',
})
export class AssetManagerService implements OnDestroy {
  private _isInit$: ReplaySubject<boolean> = new ReplaySubject(1);

  private _sub: Subscription = new Subscription();
  private _sun: DirectionalLight;
  shadowGenerator: ShadowGenerator;
  scene: Scene;

  constructor(
    private _wms: WebglManagerService,
    private _lms: LightingManagerService
  ) {
    this._init();
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  private _init(): void {
    this._sub.add(
      this._lms
        .getLightingMode$()
        .subscribe((mode: boolean) => this.setLightingMode(mode))
    );
  }

  setScene(scene: Scene): void {
    this.scene = scene;
    this._isInit$.next(true);
  }

  checkInit$(): Observable<boolean> {
    return this._isInit$.pipe(take(1));
  }

  loadEnvironment(): void {
    // Set scene coloration
    this.scene.clearColor = Color4.FromHexString(config.engine.env.clearHex);
    this.scene.ambientColor = Color3.FromHexString(
      config.engine.env.ambientHex
    );

    // Set Environment
    this.scene.createDefaultLight();
    this.scene.lights[0].intensity = config.engine.env.default_light.intensity;

    this.scene.createDefaultEnvironment({
      cameraExposure: config.camera.settings.exposure,
      createSkybox: config.engine.env.skybox.enable,
      skyboxSize: config.engine.env.skybox.size,
      skyboxColor: Color3.FromHexString(config.engine.env.skybox.hex),
      createGround: config.engine.env.ground.enable,
      groundSize: config.engine.env.ground.size,
      groundColor: Color3.FromHexString(config.engine.env.ground.hex),
    });

    // Directional Light
    this._sun = new DirectionalLight(
      'sun',
      config.engine.env.sun.dir,
      this.scene
    );
    this._sun.intensity = config.engine.env.sun.intensity;
    this._sun.diffuse = Color3.FromHexString(config.engine.env.sun.diffuse);

    // Shadows
    this.shadowGenerator = new ShadowGenerator(1024, this._sun);
    this.shadowGenerator.useBlurExponentialShadowMap = true;
    this.shadowGenerator.blurBoxOffset = 4;

    // Set effects
    const gl = new GlowLayer('glow', this.scene);
    gl.intensity = 0.4;
  }

  createBasicPBRMat(
    albedo: string | Color3 = '#526278',
    ambient: string | Color3 = '#283448',
    roughness: number = 0.9,
    metallic: number = 0,
    scene: Scene = this.scene
  ): PBRMaterial {
    const mat = new PBRMaterial('pbr', scene);
    mat.albedoColor =
      typeof albedo === 'string' ? Color3.FromHexString(albedo) : albedo;
    mat.ambientColor =
      typeof ambient === 'string' ? Color3.FromHexString(ambient) : ambient;
    mat.roughness = roughness;
    mat.metallic = metallic;
    return mat;
  }

  // Initial loading
  loadAssets(): void {
    // Block dirty during loading
    this.scene.blockMaterialDirtyMechanism = true;

    Promise.all(
      this.loadAssetContainer(config.assets, (container, asset) =>
        this._wms.addAsset(asset, container)
      )
    ).then(() => (this.scene.blockMaterialDirtyMechanism = false));
  }

  loadAssetContainer(
    assetSource: AssetConfig[],
    fn: (container: AssetContainer, asset: AssetConfig) => void
  ): Promise<any>[] {
    return assetSource.map((asset) => {
      return new Promise((res) => {
        SceneLoader.LoadAssetContainerAsync(
          ASSET_PATH,
          asset.gltf_path,
          this.scene
        ).then((container) => res(fn(container, asset)));
      });
    });
  }

  // Dark mode
  setLightingMode(isDarkMode: boolean): void {
    if (isDarkMode) {
      this._sun.intensity = config.engine.env.dark_mode.sun_intensity;
      this.scene.lights[0].intensity = config.engine.env.dark_mode.dl_intensity;
      this.scene.environmentIntensity =
        config.engine.env.dark_mode.env_intensity;
    } else {
      this._sun.intensity = config.engine.env.sun.intensity;
      this.scene.lights[0].intensity =
        config.engine.env.default_light.intensity;
      this.scene.environmentIntensity = 1.0;
    }
  }
}
