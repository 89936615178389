import { Component, OnInit } from '@angular/core';
import { WebglManagerService } from 'src/app/shared/services/webgl-manager.service';

@Component({
  selector: 'ui-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
})
export class SplashComponent implements OnInit {
  titleEn: string[] = 'Acts of Urbanism'.split('');
  titleFr: string[] = 'Entre Actes D’Urbanisme'.split('');

  loadedPercent: number = 0;
  isLoaded: boolean = false;

  constructor(private _wms: WebglManagerService) {}

  ngOnInit(): void {
    this._wms.getLoadedNum$().subscribe({
      next: (num: number) => this.updateLoadedPercent(num),
      complete: () => (this.isLoaded = true),
    });
  }

  updateLoadedPercent(goalNum: number): void {
    this.loadedPercent = Math.round((goalNum / this._wms.loadedTotal) * 100);
  }
}
