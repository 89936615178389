import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Personnel } from 'src/app/shared/models/personnel.model';

@Component({
  selector: 'dialog-personnel',
  templateUrl: './personnel.component.html',
  styleUrls: ['./personnel.component.scss'],
})
export class PersonnelComponent implements OnInit {
  isActive: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PersonnelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { personnel: Personnel }
  ) {}

  ngOnInit(): void {}

  onClose(): void {
    this.isActive = false;
    setTimeout(() => this.dialogRef.close(), 50);
  }
}
