<div class="wrapper">
  <div class="circle-outer">
    <div class="pie spinner"
      [ngStyle]="{'transform': 'rotate(' + (loadedPercent * 3.6) + 'deg)'}"></div>
    <div class="pie filler"
      [class.enable]="loadedPercent >= 50"></div>
    <div class="mask"
      [class.disable]="loadedPercent >= 50"></div>
  </div>
  <div class="circle-inner">
    <h4>{{loadedPercent}}%</h4>
  </div>
  <div class="title-wrapper uc">
    <div class="title en">
      <span *ngFor="let char of titleEn; let i = index"
        [ngClass]="'char'+i">
        {{char}}
      </span>
    </div>
    <div class="title fr">
      <span *ngFor="let char of titleFr; let i = index"
        [ngClass]="'char'+i">
        {{char}}
      </span>
    </div>
  </div>
</div>
