<div class="header">
  <span></span>
  <button mat-mini-fab
    (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="content">
  <div class="info">
    <div class="headshot"
      [ngStyle]="{ 'background-image': 'url(' + data.personnel.thumb_path + ')'}"></div>
    <div>
      <h2>{{data.personnel.name}}</h2>
      <h4 class="o-62">{{data.personnel.org}}</h4>
    </div>
  </div>

  <ng-scrollbar>
    <div class="wrapper">
      <p *ngFor="let profile of data.personnel.profile">{{profile}}</p>
      <div *ngIf="data.personnel.addl_profile.length > 0"></div>
      <p *ngFor="let addl of data.personnel.addl_profile">{{addl}}</p>
    </div>
  </ng-scrollbar>
</div>
