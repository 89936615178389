import { Injectable } from '@angular/core';
import {
  Animation,
  ArcRotateCamera,
  EasingFunction,
  Matrix,
  Plane,
  Scene,
  SineEase,
  Vector3,
} from '@babylonjs/core';
import { config } from 'src/app/_config';

@Injectable({
  providedIn: 'root',
})
export class CameraService {
  scene: Scene;
  camera: ArcRotateCamera;
  private _canvas: HTMLCanvasElement;
  private _ease: SineEase = new SineEase();

  constructor() {
    this.init();
  }

  init(): void {
    this._ease.setEasingMode(EasingFunction.EASINGMODE_EASEOUT);
  }

  loadCamera(canvas: HTMLCanvasElement, scene: Scene) {
    // Main Camera
    this.scene = scene;
    this.camera = new ArcRotateCamera(
      'cameraMain',
      config.camera.arcrotate.angle.alpha.start,
      config.camera.arcrotate.angle.beta.start,
      config.camera.arcrotate.zoom.radius,
      config.camera.arcrotate.target,
      scene
    );

    this.camera.fov = config.camera.arcrotate.fov;
    this.camera.upperRadiusLimit = config.camera.arcrotate.zoom.radius;
    this.camera.lowerRadiusLimit = config.camera.arcrotate.zoom.radius;
    // this.camera.upperBetaLimit = config.camera.arcrotate.angle.beta.upper;

    // Attach it and set active
    this._canvas = canvas;
    this.camera.attachControl(canvas, true);
    scene.activeCameras.push(this.camera);
  }

  /* Basic actions */
  toggleAutoRotate(isActive: boolean, isToggleControl: boolean = false): void {
    this.camera.useAutoRotationBehavior = isActive;
    if (this.camera.autoRotationBehavior) {
      this.camera.autoRotationBehavior.idleRotationWaitTime =
        config.camera.rotation.wait_time;
      this.camera.autoRotationBehavior.idleRotationSpeed =
        config.camera.rotation.speed;
    }
    if (isToggleControl) {
      if (isActive) this.camera.attachControl(this._canvas, true);
      else this.camera.detachControl();
    }
  }
  moveTo(
    target: Vector3,
    duration: number = 400,
    onComplete: () => void = () => {}
  ): void {
    // Lazy move with target
    Animation.CreateAndStartAnimation(
      'moveCamera',
      this.camera,
      'target',
      30,
      (duration / 1000) * 30,
      this.camera.target,
      target,
      0,
      this._ease,
      onComplete
    );
    this.smoothFixAlphaBeta(true, this.camera.alpha, duration);
    this.smoothFixAlphaBeta(false, this.camera.beta, duration);
  }

  rotate(
    alphaTarget: number,
    betaTarget: number,
    duration: number = 400,
    onComplete: () => void = () => {}
  ): void {
    Animation.CreateAndStartAnimation(
      'rotateCamera',
      this.camera,
      'alpha',
      30,
      (duration / 1000) * 30,
      this.camera.alpha,
      Math.floor(this.camera.alpha / (2 * Math.PI)) * (2 * Math.PI) +
        alphaTarget,
      0,
      this._ease
    );
    Animation.CreateAndStartAnimation(
      'rotateCamera',
      this.camera,
      'beta',
      30,
      (duration / 1000) * 30,
      this.camera.beta,
      Math.floor(this.camera.beta / (2 * Math.PI)) * (2 * Math.PI) + betaTarget,
      0,
      this._ease,
      onComplete
    );
  }

  /* Helper Methods */
  smoothFixAlphaBeta(isAlpha: boolean, val: number, duration: number): void {
    Animation.CreateAndStartAnimation(
      'smoothFixAB',
      this.camera,
      isAlpha ? 'alpha' : 'beta',
      30,
      (duration / 1000) * 30 + 1, // Add an extra frame so it finishes after the animation
      isAlpha ? this.camera.alpha : this.camera.beta,
      val,
      0,
      this._ease
    );
  }
}
