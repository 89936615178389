import { Action, Vector3 } from '@babylonjs/core';
import { take } from 'rxjs/operators';
import { WebglManagerService } from './shared/services/webgl-manager.service';

export const config = {
  data: {
    base_path: './assets/data/',
    archive: `archive.json?v=${new Date().getTime()}`,
    personnel: `personnel.json?v=${new Date().getTime()}`,
    events: `events.json?v=${new Date().getTime()}`,
    documentons: `documentons.json?v=${new Date().getTime()}`,
  },

  event: {
    // timeSoonOffset: 15 * 60 * 1000 * 8,
    timeSoonOffset: 15 * 60 * 1000,
  },

  form: {
    contact_endpoint: 'https://formspree.io/f/mdoyokap',
  },

  engine: {
    env: {
      clearHex: '#92809aff',
      ambientHex: '#ffffff',
      sun: {
        diffuse: '#ffffff',
        dir: new Vector3(-1, -5, 1),
        intensity: 1.0,
      },
      default_light: {
        intensity: 1.0,
      },
      skybox: {
        enable: false,
        hex: '#f5c7d8',
        size: 80,
      },
      ground: {
        enable: false,
        hex: '#92809a',
        size: 5,
      },
      dark_mode: {
        env_intensity: 0.1,
        dl_intensity: 0.0,
        sun_intensity: 0.2,
      },
    },
    center_offset: {
      x: 0,
      y: 0,
      z: 0,
    },
  },

  camera: {
    arcrotate: {
      fov: 1.4,
      target: new Vector3(0, -20, 0),
      angle: {
        alpha: {
          start: -Math.PI / 2,
        },
        beta: {
          start: (5 * Math.PI) / 8,
          // upper: 2.0366,
        },
      },
      zoom: {
        radius: 220,
        wh_ratio: 0.785,
      },
    },
    settings: {
      exposure: 0.9,
    },
    rotation: {
      wait_time: 2000,
      speed: -0.05,
    },
  },

  assets: [
    {
      name: 'ui.switch',
      gltf_path: 'ui.switch.glb',
      lightmap_path: '',
      anim: ['toggle', 'hover'],
      shadow: {
        cast: false,
        receive: false,
      },
      onToggleStart: (wms: WebglManagerService, args) => {
        wms.trackUIEvent('ui_switch');

        wms.setLightingMode(args.isActive);
        if (args.isActive) wms.openLiveEvent();
      },
      onToggleEnd: (wms: WebglManagerService, args) => {
        if (args.isActive)
          wms.rotateTo(Math.PI, Math.PI / 4, undefined, () => {});
      },
    },
    {
      name: 'ui.lever',
      gltf_path: 'ui.lever.glb',
      lightmap_path: '',
      anim: ['click', 'hover'],
      shadow: {
        cast: false,
        receive: false,
      },
      onClickStart: (wms: WebglManagerService) => {
        wms.trackUIEvent('ui_lever');

        wms.toggleAutoRotate(false);
        wms.rotateTo((3 * Math.PI) / 4, (2 * Math.PI) / 3);
      },
      onClickEnd: (wms: WebglManagerService) => {
        wms.ngZone.run(() => {
          const dialogRef = wms.openArchiveDialog();
          dialogRef.afterClosed().subscribe(() => wms.toggleAutoRotate(true));
        });
      },
    },
    {
      name: 'ui.pull',
      gltf_path: 'ui.pull.glb',
      lightmap_path: '',
      anim: ['click', 'hover'],
      shadow: {
        cast: false,
        receive: false,
      },
      pos: new Vector3(-120, 26.616, 0),
      resources: [
        {
          icon_name: 'logo-miro',
          title: 'Miro',
          path: 'assets/logos/miro.svg',
          link: 'https://miro.com/app/board/o9J_lMOSc3g=/',
        },
        {
          icon_name: 'logo-slack',
          title: 'Slack',
          path: 'assets/logos/slack.svg',
          link: 'https://join.slack.com/t/slack-3fr3975/shared_invite/zt-orum9sb4-pzKeSGeH2hJ68novEVg7ag',
        },
        {
          icon_name: 'logo-zoom',
          title: 'Zoom',
          path: 'assets/logos/zoom.svg',
          link: 'https://us02web.zoom.us/j/5663420488?pwd=ZjFjeEkzUWNod29SZG9iTURYUWtsdz09',
        },
        {
          icon_name: 'logo-gather',
          title: 'Gather',
          path: 'assets/logos/gather.svg',
          link: 'https://gather.town/i/Lzt4qXJB',
        },
        {
          icon_name: 'logo-hubs',
          title: 'Mozilla Hubs',
          path: 'assets/logos/hubs.svg',
          link: 'https://hub.link/Y4PpbeU',
        },
      ],
      onClickStart: (wms: WebglManagerService) => {
        wms.trackUIEvent('ui_pull');

        wms.toggleAutoRotate(false);
        wms.rotateTo((3 * Math.PI) / 2, 0, 2000, () => {
          // Get the x offset after rotation - so the projection is from the right direction
          // Include the x position from the model
          const xOffset =
            120 + wms.getMoveTarget(new Vector3(200, 0, 0), 26.616).x;
          const vecOffset =
            xOffset > 0 ? Vector3.Left().scale(xOffset) : Vector3.Zero();
          const duration = xOffset > 0 ? 250 : 1;

          // Move it
          wms.moveTo(vecOffset, duration, () => {
            wms.ngZone.run(() => {
              const dialogRef = wms.openAtelierMenu();
              // On window resize, close it
              const sub = wms
                .getEngineResize$()
                .pipe(take(1))
                .subscribe(() => dialogRef.close());
              dialogRef.afterClosed().subscribe(() => {
                sub.unsubscribe();
                wms.moveReset(duration, () => wms.toggleAutoRotate(true));
              });
            });
          });
        });
      },
    },
    {
      name: 'ui.button',
      gltf_path: 'ui.button.glb',
      lightmap_path: '',
      anim: ['click', 'trigger', 'hover'],
      shadow: {
        cast: false,
        receive: false,
      },
      pos: new Vector3(0, -33.8, 123.5),
      onClickStart: (wms: WebglManagerService) => {
        wms.trackUIEvent('ui_button');

        wms.toggleAutoRotate(false);
        wms.rotateTo(0, (2 * Math.PI) / 5, undefined, () => {
          wms.ngZone.run(() => {
            const dialogRef = wms.openDocumentonFilter();
            dialogRef.afterClosed().subscribe(() => wms.toggleAutoRotate(true));
          });
        });
      },
      onTriggerStart: (wms: WebglManagerService) => {
        wms.toggleAutoRotate(false);
      },
      onTriggerEnd: (wms: WebglManagerService) => {
        wms.ngZone.run(() => {
          const dialogRef = wms.openDocumentonMedia();
          dialogRef.afterClosed().subscribe(() => wms.toggleAutoRotate(true));
        });
      },
    },
    {
      name: 'donut.cake',
      gltf_path: 'donut.cake.glb',
      lightmap_path: '',
      anim: [],
      shadow: {
        cast: false,
        receive: false,
      },
    },
    {
      name: 'donut.frosting',
      gltf_path: 'donut.frosting.glb',
      lightmap_path: '',
      anim: [],
      shadow: {
        cast: false,
        receive: false,
      },
      disableBackfaceCull: true,
    },
    {
      name: 'auditorium',
      gltf_path: 'auditorium.glb',
      lightmap_path: '',
      anim: [],
      shadow: {
        cast: false,
        receive: false,
      },
    },
    {
      name: 'slides',
      gltf_path: 'slides.glb',
      lightmap_path: '',
      anim: [],
      shadow: {
        cast: false,
        receive: false,
      },
    },
    {
      name: 'undertread',
      gltf_path: 'undertread.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: false,
        receive: false,
      },
      loadOn: 'desktop',
    },
    {
      name: 'stair',
      gltf_path: 'stair.glb',
      lightmap_path: '',
      anim: [],
      shadow: {
        cast: false,
        receive: false,
      },
    },
    {
      name: 'factory',
      gltf_path: 'factory.glb',
      lightmap_path: '',
      anim: [],
      shadow: {
        cast: false,
        receive: false,
      },
    },
    {
      name: 'favela.huts',
      gltf_path: 'favela.huts.glb',
      lightmap_path: '',
      anim: [],
      shadow: {
        cast: false,
        receive: false,
      },
    },
    {
      name: 'favela.bigBox',
      gltf_path: 'favela.bigBox.glb',
      lightmap_path: '',
      anim: [],
      shadow: {
        cast: false,
        receive: false,
      },
    },
    {
      name: 'favela.bars',
      gltf_path: 'favela.bars.glb',
      lightmap_path: '',
      anim: [],
      shadow: {
        cast: false,
        receive: false,
      },
    },
    {
      name: 'favela.grates',
      gltf_path: 'favela.grates.glb',
      lightmap_path: '',
      anim: [],
      shadow: {
        cast: false,
        receive: false,
      },
      loadOn: 'desktop',
    },
    {
      name: 'bubbles',
      gltf_path: 'bubbles.glb',
      lightmap_path: '',
      anim: [],
      shadow: {
        cast: false,
        receive: false,
      },
    },
    {
      name: 'marioPipes',
      gltf_path: 'marioPipes.glb',
      lightmap_path: '',
      anim: [],
      shadow: {
        cast: false,
        receive: false,
      },
    },
    {
      name: 'pipes',
      gltf_path: 'pipes.glb',
      lightmap_path: '',
      anim: [],
      shadow: {
        cast: false,
        receive: false,
      },
    },
    {
      name: 'plumbing.001',
      gltf_path: 'plumbing.001.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: false,
        receive: false,
      },
    },
    {
      name: 'plumbing.002',
      gltf_path: 'plumbing.002.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: false,
        receive: false,
      },
    },
    {
      name: 'plumbing.003',
      gltf_path: 'plumbing.003.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: false,
        receive: false,
      },
    },
    {
      name: 'plumbing.004',
      gltf_path: 'plumbing.004.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: false,
        receive: false,
      },
    },
    {
      name: 'plumbing.005',
      gltf_path: 'plumbing.005.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: false,
        receive: false,
      },
    },
    {
      name: 'plumbing.006',
      gltf_path: 'plumbing.006.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: false,
        receive: false,
      },
    },
    {
      name: 'plumbing.007',
      gltf_path: 'plumbing.007.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: false,
        receive: false,
      },
    },
    {
      name: 'plumbing.008',
      gltf_path: 'plumbing.008.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: false,
        receive: false,
      },
    },
    {
      name: 'plumbing.009',
      gltf_path: 'plumbing.009.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: false,
        receive: false,
      },
    },
    {
      name: 'plumbing.rings',
      gltf_path: 'plumbing.rings.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: false,
        receive: false,
      },
      loadOn: 'desktop',
    },
    {
      name: 'prop',
      gltf_path: 'prop.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: false,
        receive: false,
      },
      disableBackfaceCull: true,
    },
    {
      name: 'satellite',
      gltf_path: 'satellite.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: false,
        receive: false,
      },
      disableBackfaceCull: true,
    },
    {
      name: 'cloud.001',
      gltf_path: 'cloud.001.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: true,
        receive: false,
      },
    },
    {
      name: 'cloud.002',
      gltf_path: 'cloud.002.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: true,
        receive: false,
      },
    },
    {
      name: 'cloud.003',
      gltf_path: 'cloud.003.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: true,
        receive: false,
      },
    },
    {
      name: 'cloud.004',
      gltf_path: 'cloud.004.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: true,
        receive: false,
      },
    },
    {
      name: 'cloud.005',
      gltf_path: 'cloud.005.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: true,
        receive: false,
      },
    },
    {
      name: 'cloud.006',
      gltf_path: 'cloud.006.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: true,
        receive: false,
      },
    },
    {
      name: 'cloud.007',
      gltf_path: 'cloud.007.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: true,
        receive: false,
      },
    },
    {
      name: 'plunger.001',
      gltf_path: 'plunger.001.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: false,
        receive: false,
      },
      anim_delay: 750,
    },
    {
      name: 'plunger.002',
      gltf_path: 'plunger.002.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: false,
        receive: false,
      },
      anim_delay: 500,
    },
    {
      name: 'plunger.003',
      gltf_path: 'plunger.003.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: false,
        receive: false,
      },
      anim_delay: 250,
    },
    {
      name: 'plunger.004',
      gltf_path: 'plunger.004.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: false,
        receive: false,
      },
    },
    {
      name: 'neon',
      gltf_path: 'neon.glb',
      lightmap_path: '',
      anim: ['idle'],
      shadow: {
        cast: false,
        receive: false,
      },
      loadOn: 'event',
    },
  ],
};
