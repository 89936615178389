import { Injectable, NgZone, OnDestroy } from '@angular/core';
import {
  Engine,
  Matrix,
  Plane,
  Scene,
  Vector3,
  Viewport,
} from '@babylonjs/core';
import { Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RenderProjectionService implements OnDestroy {
  private _isInit$: ReplaySubject<boolean> = new ReplaySubject(1);

  private _engine: Engine;
  private _scene: Scene;

  constructor(private _ngZone: NgZone) {}

  setRenderer(engine: Engine, scene: Scene): void {
    this._engine = engine;
    this._scene = scene;

    // Broadcast ready
    this._isInit$.next(true);
  }

  ngOnDestroy(): void {
    this._scene.onBeforeRenderObservable.clear();
  }

  checkInit$(): Observable<boolean> {
    return this._isInit$.pipe(take(1));
  }

  addOBR(
    inputCallback: () => Vector3[],
    outputCallback: (screenCoords: Vector3, i: number) => void,
    isOnce: boolean = false
  ): void {
    this.checkInit$().subscribe(() => {
      this._ngZone.runOutsideAngular(() => {
        isOnce
          ? this._scene.onBeforeRenderObservable.addOnce(() =>
              this.handleOBR(inputCallback, outputCallback)
            )
          : this._scene.onBeforeRenderObservable.add(() =>
              this.handleOBR(inputCallback, outputCallback)
            );
      });
    });
  }

  handleOBR(
    inputCallback: () => Vector3[],
    outputCallback: (screenCoords: Vector3, i: number) => void
  ): void {
    inputCallback().forEach((coord, i) => {
      let screenCoords = Vector3.Project(
        coord,
        Matrix.Identity(),
        this._scene.getTransformMatrix(),
        new Viewport(
          0,
          0,
          this._engine.getRenderWidth(),
          this._engine.getRenderHeight()
        )
      );

      outputCallback(screenCoords, i);
    });
  }

  getWorldCoords(screenCoords: Vector3, zPos: number): Vector3 {
    let plane = Plane.FromPositionAndNormal(
      new Vector3(0, zPos, 0),
      new Vector3(0, 1, 0)
    );
    let ray = this._scene.createPickingRay(
      screenCoords.x,
      screenCoords.y,
      Matrix.Identity(),
      this._scene.getCameraByName('cameraMain'),
      false
    );
    let distance = ray.intersectsPlane(plane);
    let pickOrigin: Vector3 = ray.direction.scale(distance).add(ray.origin);

    return pickOrigin;
  }
}
