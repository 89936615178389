import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { SafePipe } from './pipes/safe.pipe';
import { ContactComponent } from './forms/contact/contact.component';
import { MaterialModule } from '../material.module';

@NgModule({
  declarations: [SafePipe, ContactComponent],
  imports: [CommonModule, MatIconModule, MaterialModule],
  exports: [SafePipe, ContactComponent],
})
export class SharedModule {}
