import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';

import { EngineComponent } from './engine/engine.component';
import { SplashComponent } from './splash/splash.component';
import { MaterialModule } from '../material.module';
import { BackgroundComponent } from './background/background.component';
import { HeaderComponent } from './header/header.component';
import { DrawerComponent } from './drawer/drawer.component';
import { SharedModule } from '../shared/shared.module';
import { AtelierMenuComponent } from './dialog/atelier-menu/atelier-menu.component';
import { AtelierMenuWrapperDirective } from './dialog/atelier-menu/atelier-menu-wrapper.directive';
import { ArchiveComponent } from './dialog/archive/archive.component';
import { QuickLinksComponent } from './quick-links/quick-links.component';
import { PersonnelComponent } from './dialog/personnel/personnel.component';
import { DocumentonFilterComponent } from './dialog/documenton-filter/documenton-filter.component';
import { DocumentonMediaComponent } from './dialog/documenton-media/documenton-media.component';
import { DocumentonFilterWrapperDirective } from './dialog/documenton-filter/documenton-filter-wrapper.directive';

@NgModule({
  declarations: [
    EngineComponent,
    SplashComponent,
    BackgroundComponent,
    HeaderComponent,
    DrawerComponent,
    AtelierMenuComponent,
    AtelierMenuWrapperDirective,
    ArchiveComponent,
    QuickLinksComponent,
    PersonnelComponent,
    DocumentonFilterComponent,
    DocumentonMediaComponent,
    DocumentonFilterWrapperDirective,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MaterialModule,
    SharedModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
  ],
  exports: [
    EngineComponent,
    SplashComponent,
    BackgroundComponent,
    HeaderComponent,
    DrawerComponent,
    QuickLinksComponent,
  ],
})
export class UIModule {}
