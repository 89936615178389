import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { NgScrollbar } from 'ngx-scrollbar';
import { Archive } from 'src/app/shared/models/archive.model';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'dialog-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss'],
})
export class ArchiveComponent implements OnInit {
  isActive: boolean = false;

  archives: Archive[] = [];
  filteredArchive: Archive[] = [];
  activeArchive: Archive;

  @ViewChild(NgScrollbar) scrollable: NgScrollbar;

  @ViewChild(MatInput) input: MatInput;
  control = new FormControl();

  constructor(
    public dialogRef: MatDialogRef<ArchiveComponent>,
    private _ds: DataService
  ) {}

  ngOnInit(): void {
    this._ds.getAllArchives$().subscribe((archives: Archive[]) => {
      this.archives = archives;
      this.activeArchive = archives[0];
      this.filteredArchive = archives.filter(
        (a) => a.title !== this.activeArchive.title
      );
    });
  }

  onClose(): void {
    this.isActive = false;
    setTimeout(() => this.dialogRef.close(), 50);
  }

  onClear(): void {
    this.input.value = '';
    this.control.setValue('clear filter string');
    this.control.setValue('');
  }

  onSelect(newArchive: Archive): void {
    this.activeArchive = newArchive;
    this.filteredArchive = this.archives.filter(
      (a) => a.title !== this.activeArchive.title
    );

    this.scrollable.scrollTo({ top: 0 });
  }
}
