import { Directive, ElementRef } from '@angular/core';
import { Vector3 } from '@babylonjs/core';

@Directive({
  selector: '[atelierMenuWrapper]',
})
export class AtelierMenuWrapperDirective {
  constructor(public elem: ElementRef) {}

  setPos(screenCoords: Vector3): void {
    this.elem.nativeElement.style.right = `${
      window.innerWidth - screenCoords.x
    }px`;
    this.elem.nativeElement.style.top = `${screenCoords.y}px`;
  }
}
