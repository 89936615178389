import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActiveDialogService {
  isDialogOpen: boolean = false;
  isDialogOpen$: Subject<boolean> = new Subject();

  constructor() {}

  setDialogOpen(dialogRef: MatDialogRef<any>): void {
    this.isDialogOpen = true;
    this.isDialogOpen$.next(true);
    dialogRef.afterClosed().subscribe(() => {
      this.isDialogOpen = false;
      this.isDialogOpen$.next(false);
    });
  }

  getDialogOpened$(): Observable<boolean> {
    return this.isDialogOpen$.asObservable();
  }
}
