import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { config } from 'src/app/_config';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  constructor(
    private _iconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer
  ) {
    this._init();
  }

  private _init(): void {
    config.assets
      .find((a) => a.name === 'ui.pull')
      .resources.forEach((resource) => {
        this._iconRegistry.addSvgIcon(
          resource.icon_name,
          this._sanitizer.bypassSecurityTrustResourceUrl(resource.path)
        );
      });
  }
}
