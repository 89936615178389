import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { LightingManagerService } from 'src/app/shared/services/lighting-manager.service';

@Component({
  selector: 'ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() drawer: MatSidenav;
  constructor(public lms: LightingManagerService) {}

  ngOnInit(): void {}

  toggleLighting(): void {
    this.lms.toggleLighting();
  }
  toggleSound(): void {}
}
