<form #contactForm="ngForm"
  [class.hide]="isFormSubmitted">
  <mat-form-field>
    <mat-label for="email">Email</mat-label>
    <input matInput
      email
      required
      type="email"
      name="email"
      [(ngModel)]="formData.email"
      #email="ngModel">
    <div *ngIf="email.invalid && (email.dirty || email.touched)">
      <mat-error *ngIf="email.errors.required">Please enter an email</mat-error>
      <mat-error *ngIf="email.errors.email">Please enter a valid email</mat-error>
    </div>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Message</mat-label>
    <textarea matInput
      required
      minlength="4"
      name="message"
      [(ngModel)]="formData.message"
      #message="ngModel"></textarea>
    <div *ngIf="message.invalid && (message.dirty || message.touched)">
      <mat-error *ngIf="message.errors.required">Please enter a message</mat-error>
      <mat-error *ngIf="message.errors.minlength">Please enter a longer message</mat-error>
    </div>
  </mat-form-field>
  <button mat-raised-button
    class="uc"
    color="primary"
    type="submit"
    [disabled]="!contactForm.form.valid"
    (click)="onContactFormSubmit()">Submit</button>
</form>
<h4 *ngIf="isFormSubmitted">Thanks for the message. We'll be in touch soon.</h4>
