<div class="header">
  <h4 class="title">Archive</h4>
  <button mat-mini-fab
    (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="content">
  <ng-scrollbar>
    <div *ngIf="activeArchive"
      class="viewer">
      <vg-player>
        <vg-overlay-play></vg-overlay-play>
        <vg-buffering></vg-buffering>

        <vg-scrub-bar>
          <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
          <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
        </vg-scrub-bar>

        <vg-controls>
          <vg-play-pause></vg-play-pause>

          <vg-time-display vgProperty="current"
            vgFormat="mm:ss"></vg-time-display>
          <div class="slash">
            <span class="mat-h4">/</span>
          </div>
          <vg-time-display vgProperty="total"
            vgFormat="mm:ss"></vg-time-display>

          <span class="spacer"></span>
          <vg-mute></vg-mute>
          <vg-volume></vg-volume>

          <vg-fullscreen></vg-fullscreen>
        </vg-controls>

        <video #media
          [vgMedia]="media"
          [src]="activeArchive.video_path"
          id="singleVideo"
          preload="auto">
        </video>
      </vg-player>
    </div>

    <div ngClass.lt-sm="mobile"
      *ngIf="activeArchive"
      class="more">
      <div class="attribution">
        <div class="title-block">
          <h1 class="title w-600">{{activeArchive.title}}</h1>
          <h4 class="author w-500">{{activeArchive.artist}}</h4>
          <h4 class="time-place o-62">{{activeArchive.place}} | {{activeArchive.year}}</h4>
        </div>
        <mat-divider></mat-divider>
        <div class="description">{{activeArchive.summary}}

          <div *ngIf="activeArchive.credits.length > 0"
            class="credits">
            <h4 class="w-500">Additional Credits:</h4>
            <ul>
              <li *ngFor="let credit of activeArchive.credits">{{credit}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="list">
        <!-- <form>
          <mat-form-field appearance="outline">
            <input #input
              matInput
              type="text"
              placeholder="Search"
              [formControl]="control"
              [matAutocomplete]="auto">
            <mat-autocomplete class="search-results"
              #auto="matAutocomplete">
            </mat-autocomplete>
            <button mat-button
              *ngIf="input.value"
              matSuffix
              mat-icon-button
              (click)="onClear()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </form> -->
        <a *ngFor="let archive of filteredArchive"
          class="thumb"
          (click)="onSelect(archive)">
          <div class="img"
            [ngStyle]="{ 'background-image': 'url(' + archive.thumb_path + ')'}"></div>
          <div class="title-block">
            <h3 class="title w-500">{{archive.title}}</h3>
            <h4 class="author o-62">{{archive.artist}}</h4>
            <h4 class="time-place o-62">{{archive.year}}</h4>
          </div>
        </a>
      </div>
    </div>
  </ng-scrollbar>
</div>
