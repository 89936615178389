import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { config } from 'src/app/_config';

@Component({
  selector: 'form-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  formData: any = {};
  isFormSubmitted: boolean = false;

  constructor(private _http: HttpClient) {}

  ngOnInit(): void {}

  onContactFormSubmit(): void {
    this._http
      .post(config.form.contact_endpoint, {
        _replyto: this.formData.email,
        message: this.formData.message,
      })
      .subscribe((resp) => {
        this.isFormSubmitted = true;
      });
  }
}
