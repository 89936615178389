<!-- ////DEBUG: Comment in -->
<!-- <mat-sidenav-container hasBackdrop=false> -->
<!-- ////DEBUG: Comment out -->
<mat-sidenav-container hasBackdrop=false
  [class.loading-complete]="splash.isLoaded">
  <mat-sidenav mode="over"
    autoFocus=false
    #drawer>
    <ui-drawer [drawer]="drawer"></ui-drawer>
  </mat-sidenav>
  <mat-sidenav-content>
    <!-- Header -->
    <header class="fade partial"
      [class.deactivate]="ads.isDialogOpen">
      <ui-header [drawer]="drawer"></ui-header>
    </header>

    <!-- Quick Links -->
    <ui-quick-links class="fade"
      [class.deactivate]="drawer.opened || ads.isDialogOpen "
      [drawer]="drawer"></ui-quick-links>

    <!-- Model and background -->
    <main>
      <ui-background></ui-background>
      <ui-engine></ui-engine>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>

<!-- ////DEBUG: Comment out -->
<ui-splash #splash
  class="fade full-remove"
  [class.disable]="splash.isLoaded"></ui-splash>
