<mat-toolbar>
  <button mat-icon-button
    (click)="drawer.toggle()">
    <mat-icon *ngIf="!drawer.opened">menu</mat-icon>
    <mat-icon *ngIf="drawer.opened">close</mat-icon>
  </button>
  <div class="logo-wrapper uc">
    Acts of Urbanism
  </div>
  <span class="spacer"></span>
  <!-- <button mat-icon-button
    (click)="toggleSound()">
    <mat-icon>volume_off</mat-icon>
  </button> -->
  <button mat-icon-button
    (click)="toggleLighting()">
    <mat-icon *ngIf="!lms.isDarkMode">light_mode</mat-icon>
    <mat-icon *ngIf="lms.isDarkMode">dark_mode</mat-icon>
  </button>

</mat-toolbar>
