<div atelierMenuWrapper
  class="wrapper"
  [class.active]="isActive">
  <div class="button-wrapper">
    <div class="title">
      <h4>Close</h4>
    </div>
    <button mat-mini-fab
      (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div *ngFor="let item of asset.resources"
    class="button-wrapper">
    <div class="title">
      <h4>{{item.title}}</h4>
    </div>
    <button mat-mini-fab
      (click)="onLink(item.link)">
      <mat-icon [svgIcon]="item.icon_name"></mat-icon>
    </button>
  </div>

</div>
