import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LightingManagerService } from 'src/app/shared/services/lighting-manager.service';
import { ActiveDialogService } from './shared/services/active-dialog.service';
import { IconService } from './shared/services/icon.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Acts of Urbanism';
  @HostBinding('class') public lightingMode: string = 'light-mode';
  private _sub: Subscription = new Subscription();

  constructor(
    public ads: ActiveDialogService,
    private _lms: LightingManagerService,
    private _iconService: IconService
  ) {}

  ngOnInit(): void {
    this._sub.add(
      this._lms
        .getLightingMode$()
        .subscribe((isDarkMode: boolean) =>
          isDarkMode
            ? (this.lightingMode = 'dark-mode')
            : (this.lightingMode = 'light-mode')
        )
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
