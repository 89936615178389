<div class="stacked-buttons">
  <button class="pop-in"
    mat-mini-fab
    matTooltip="Atelier"
    matTooltipPosition="right"
    [matTooltipClass]="[lms.isDarkMode ? 'dark-mode' : 'light-mode', 'tooltip']"
    (click)="onQuickLink('ui.pull')">
    <mat-icon>architecture</mat-icon>
  </button>
  <button class="pop-in"
    mat-mini-fab
    matTooltip="Documenton"
    matTooltipPosition="right"
    [matTooltipClass]="[lms.isDarkMode ? 'dark-mode' : 'light-mode', 'tooltip']"
    (click)="onQuickLink('ui.button')">
    <mat-icon>document_scanner</mat-icon>
  </button>
  <button class="pop-in"
    mat-mini-fab
    matTooltip="Archive"
    matTooltipPosition="right"
    [matTooltipClass]="[lms.isDarkMode ? 'dark-mode' : 'light-mode', 'tooltip']"
    (click)="onQuickLink('ui.lever')">
    <mat-icon>video_library</mat-icon>
  </button>
  <button class="pop-in"
    mat-mini-fab
    matTooltip="Theater"
    matTooltipPosition="right"
    [matTooltipClass]="[lms.isDarkMode ? 'dark-mode' : 'light-mode', 'tooltip']"
    (click)="onQuickLink('ui.switch')">
    <mat-icon>theater_comedy</mat-icon>
  </button>
</div>
